.MuiList-root li {
  font-size: 16px !important;
  /*color: #D38C33;*/
}

.profile img {
  width: 36px;
  height: 36px;
  object-fit: cover;
  border-radius: 50%;
}

header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 90px;
  padding: 1rem 2rem;
  z-index: 999;
  margin: 2vh 0;

}

.stepperText_container {
  padding-left: 0px;
  padding-bottom: 10px;
}

.css-13cymwt-control {
  background-color: #F5F6F9 !important;
  border-color: transparent !important;
  border-width: 0 !important;
}

.css-13cymwt-control span {
  color: #48494C !important;
}

.slider {
  margin-left: -15px;
}

.mobile_header {
  display: none;
  width: 100%;
}

.language_home_container {
  position: relative;
  top: 0px;
  right: 0rem;
}

.language_container {
  justify-content: flex-end;
}

@media screen and (min-width: 1920px) and (max-width: 2100px) {
  .slider {
    margin-left: -45px;
  }
}

@media screen and (min-width: 821px) and (max-width: 1024px) {
  .stepper_view{
    width: 100%;
    margin-left: 0;
  }
}
 
@media screen and (max-width: 820px) {
  .mobile_header {
    width: 100%;
    display: block !important;
    height: 70px;
    padding: .5rem 2rem;
    z-index: 999;
  }

  header {
    display: flex;
    justify-content: center;
    left: 0;
    align-items: center;
    width: 100%;
    height: 100px;
 
    z-index: 999;
  }

  .header_container {
    display: block !important;
  }

  .slider {
   
  }
 
  .language_dropdown {
    display: block;
    position: relative;
    top: 90px;
    right: 70px;
    font-size: .7em;
    border: none;
    outline: none;
  }

  .language_dropdown option {
    font-size: .7em;
  }

  .stepperText {
    text-align: center;
  }

  .card-radio {
    font-size: 14px !important;
  }

  .logo_container {
    display: none;
  }

  .stepperText_container {
    text-align: left;
  }

  .display_text {
    display: none;
  }

  .terms_text {
    font-size: 13px;
  }
}

@media screen and (max-width: 668px) {
  header {
    justify-content: flex-start !important;
    padding: 0rem 0rem;
  }

  .language_container {
    justify-content: space-between;
  }

  .language_home_container {
    position: absolute;
    top: 85px;
    right: 0;
  }

  .mobile_header {
    height: 85px;
    width: 100%;
    height: 70px;
    padding: 0rem 0rem;
    z-index: 999;
  }

  .slider {
    margin-left: 0px !important;
  }

  .dropdown {
    font-size: .7em !important;
  }

  .css-1xc3v61-indicatorContainer {
    padding: 4px !important;
  }

  .stepperText_container {
    text-align: center;
  }

  .home_container {
    position: absolute;
    top: 10px;
    width: 40px;
    right: 10px;
  }

  .stepper_view {
    position: relative;
    top: 20px;
    width: 100%;
    margin: 0 0;
  }
}