.overlay {
  position: absolute;
  width: 100vw;
  height: 100%;
  top: 0;
  left: 0;
  background-color: transparent;
}

tspan {
  font-size: .7em;
}

.MuiDialog-paperFullWidth {
  border-radius: 20px !important;
}

.header_container {
  border-bottom : 2px solid #D38C33;
  padding: 10px 0;
  color: #D38C33;
  font-weight: bold;
  text-transform: uppercase;
  display: none;
}

.css-o3ukdw::after {
  border-bottom: 2px solid #D38C33 !important;
}

.css-t3ipsp-control, .css-t3ipsp-control:hover, .css-t3ipsp-control:focus {
  box-shadow: 0 0 0 .1px #D38C33 !important;
  border-color: #D38C33 !important;
}

.css-m5hdmq-MuiInputBase-root-MuiInput-root-MuiSelect-root:after {
  border-bottom-color: #D38C33 !important;
}

.css-d7l1ni-option {
  /*background-color: #D38C33 !important;
  color: #fff !important;
  */
}

.MuiSwitch-track {
  background-color: #D38C33 !important;
}

.movie_img {
  width: 80px;
  height: 45px;
}

.css-348fk2.Mui-focused, label.MuiFormLabel-filled {
  color: #D38C33 !important;
}

.css-v4u5dn-MuiInputBase-root-MuiInput-root::after, .css-zrqpq1::after {
  border-bottom: 2px solid #D38C33 !important;
}

.css-1c2i806-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
  color:  #D38C33 !important;
}

p {
  margin-bottom: 0 !important;
}

.css-1sbu82h-MuiDataGrid-root .MuiDataGrid-cellContent {
  text-overflow: none;
  overflow: visible;
}

fieldset.scheduler-border {
  border-radius: 5px !important;
  border: 1px groove #ddd !important;
  padding: 0 1em 0em 1em !important;
  margin: 0 0 1.5em 0 !important;
  -webkit-box-shadow:  0px 0px 0px 0px #000 !important;
  box-shadow:  0px 0px 0px 0px #000 !important;
}

legend.scheduler-border {
  font-size: 1em !important;
  text-align: left !important;
  width:auto;
  border-radius: 5px !important;
  padding:0 10px !important;
  border-bottom:none;
  float: none;
  color: #ef2b2f;
  font-weight: bold;

  /*margin-left: 0% !important;
  position: relative;
  text-align: center !important;
  width: auto;
  */
}

a {
  text-decoration: none !important;
}

.text-danger {
  font-size: 12px;
}

.MuiStep-root {
  padding: 0 !important;
}

.css-117w1su-MuiStepIcon-text {
  font-size: .5em !important;
}

.stepper_view a {
  color: #fff !important;
}

.stepperText {
  font-size: 22px;
  font-weight: medium;
  font-family: "Roboto Slab";
  color: #48494C;
  text-align: end;
}

.language_dropdown {
  outline: none;
}

.card {
  border-radius: 12px !important;
  width: 60px;
  height: 40px;
}

.red {
  color: #ff0000;
  font-weight: bold;
}

.media_text {
  font-size: 12px;
}

._loading_overlay_content {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.login-cotainer {
  position: relative;
}

.login-submit {
  position: absolute;
  right: 10px;
  font-size: .9em;
  top: 38%;
}

::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 2px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: grey; 
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: darkgrey; 
}

.parent_container {
/*  overflow: auto;*/
  position: relative;
}

.ax-button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  outline: none;
  cursor: pointer;
  outline: inherit;
}

.ax-button:focus {
  outline: none;
}

.container-view input[type="radio"] + label .text_value {
  color: #6c757d;
}

.container-view input[type="radio"] + label .fa-regular {
  color: #6c757d;
}

.container-view input[type="radio"]:checked + label .fa-face-frown-open, .container-view input[type="radio"]:checked + label .fa-face-frown-open + div {
  color: #dc3545 !important;
}

.container-view input[type="radio"]:checked + label .fa-face-frown, .container-view input[type="radio"]:checked + label .fa-face-frown + div {
  color: #e83e8c;
}

.container-view input[type="radio"]:checked + label .fa-face-meh, .container-view input[type="radio"]:checked + label .fa-face-meh + div  {
  color: #fd7e14;
}

.container-view input[type="radio"]:checked + label .fa-face-smile, .container-view input[type="radio"]:checked + label .fa-face-smile + div  {
  color: #20c997;
}

.container-view input[type="radio"]:checked + label .fa-face-laugh, .container-view input[type="radio"]:checked + label .fa-face-laugh + div  {
  color: #28a745;
}

.container-view input:focus {
  outline: none;
  box-shadow: none;
}

.disabled {
  opacity: 0.5;
}

.DatePicker div.MuiTextField-root {
  padding-top: 8px;
  width: 100% !important;
}

.DatePicker .css-13bvbr1-MuiStack-root {
  width: 100% !important;
}
.DatePicker input {
  padding: 14.5px 0px;
}

.DatePicker fieldset {
  border-top-color: transparent !important;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: rgba(0,0,0,.47) !important;
  border-radius: 0 !important;
  border-bottom-width: 1px !important;
 
}

.DatePicker label {
  left: -13px;
}

.DatePicker legend {
  padding-inline-start: 0px !important;
  padding-inline-end: 0px !important;
}

.css-1xhypcz-MuiStack-root>.MuiTextField-root {
  min-width: 0 !important;
  width: 100%;
}

.a11y {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(0px 0px 99.9% 99.9%);
}

.card-wrapper input[type="radio"] + label, .movie-wrapper input[type="radio"] + label {
  cursor: pointer;
}

.card-wrapper input[type="radio"]:checked + label, .movie-wrapper input[type="radio"]:checked + label {
  background-color: #FBDFD9;
}

.card-wrapper input[type="radio"]:checked + label i, .movie-wrapper input[type="radio"]:checked + label i {
  color: #F63107 !important;
}

.card-wrapper input[type="checkbox"] + label, .movie-wrapper input[type="checkbox"] + label{
  cursor: pointer;
}

.card-wrapper input[type="checkbox"]:checked + label, .movie-wrapper input[type="checkbox"]:checked + label {
  background-color: #FBDFD9;
  border:  1px solid #F63107 !important;
  border-radius: 5px;

}

.card-wrapper input[type="checkbox"]:checked + label i, .movie-wrapper input[type="checkbox"]:checked + label i {
  color: #F63107 !important;
}

.wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1px;
  grid-auto-rows: minmax(10px, auto);
}

.media_wrapper {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 40px;
  grid-auto-rows: minmax(1px, auto);
}

.select .MuiFormControl-root, .datePicker .MuiFormControl-root {
  width: 100%;
}

.css-1u9des2-indicatorSeparator {
  background-color: transparent !important;
}

.feedback {
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-attachment: fixed;
}

.nextBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  max-width: 200px;
  width: 100%;
  border: none;
  outline: none;
  color: #fff;
  border-radius: 5px;
  /*margin: 25px 0;*/
  background: linear-gradient(to right, #EB7078 0%, #EB7078 100%);
  transition: all 0.3s linear;
  cursor: pointer;
}

.backBtn {
  align-items: center;
  justify-content: center;
  height: 45px;
  max-width: 200px;
  width: 100%;
  background-color: transparent;
  outline: none;
  border-radius: 25px;
  margin: 25px 0;
  border: 1px solid #AC5056;
  transition: all 0.3s linear;
  cursor: pointer;
  color: #AC5056;
}

.backBtn i {
  border:  none;
}

.backBtn i::before {
  transform: rotate(180deg);
  border:  none;
}

.backBtn:hover i::before {
  color: #fff;
}

.backBtn:hover {
  background: linear-gradient(to right, #c7202c 0%, #fe5261 100%);
  color:  #fff !important;
  cursor: pointer;
}

.container-view form .btnText {
  font-size: 14px;
  font-weight: 400;
}

form.thank_you .thank_you_consent {
  font-size: 0.85em;
  text-align: center;
}

form.user_info label, form.user_info .MuiTypography-root,
form.thank_you label, form.thank_you .MuiTypography-root,
.source_info label {
  font-size: .95em !important;
  padding-bottom: 5px;
  color: var(--dark);
  opacity: .8 !important;
}

form button i, form .backBtn i {
  margin: 0 6px;
}

form .backBtn i {
  transform: rotate(180deg);
}

form .buttons {
  display: flex;
  align-items: center;
}

form .buttons button , .backBtn {
  margin-right: 14px;
}

.form-label {
  font-size: 1em;
}

footer div.text-white {
  width: 27%;
}

@media screen and (max-width: 820px) {

  body {
    background-size: 100% 100%;
  }

  .css-1869usk-MuiFormControl-root {
    min-width: 50px !important;
  }

  .stepperText_container {
  }

  .stepperText {
    font-size: 14px;
  }

  .wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 3px;
    grid-auto-rows: minmax(1px, auto);
  }

  .media_wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    grid-auto-rows: minmax(10px, auto);
  }

  .login_form {
    width: 90%;
    margin: 0 5%;
  }

  .login_phone_hint {
    font-size: .8em;
  }

  .login_consent {
    font-size: .8em;
  }
}

@media screen and (max-width: 640px) {
  .select .MuiFormControl-root {
    min-width: 94px !important;
  }
}
