.css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked {
    color: #D38C33 !important;
}

.css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track {
   background-color: #D38C33 !important;
}

.login_container {
  font-size: 1.2em;
  position: relative;
}

.login_container_view {
  min-height: 100vh;
}

.login_content_viewer {
  width: 70%;
  margin: 0 15%;
}

.terms_text {
  font-weight: 500;
  font-size: 14px;
}

.textField .MuiFormControl-root {
	width: 100%;
}

.otp-details {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.otp-field {
	width: calc(100%/5);
	padding: 7px !important;
	margin: 0 1.5% !important;
	background: transparent;
	color: #D38C33;
	outline: none;
	border: none;
	text-align: center;
	border-bottom: 3px solid #D38C33;
	font-weight: bold;
  font-size: 2.5em;
}

section .title{
    position: relative;
    text-align: center;
    font-size: 40px;
    font-weight: 500;
    margin-bottom: 60px;
    padding-bottom: 20px;
}

section .title::before{
    content: "";
    position: absolute;
    bottom: 0px;
    left: 50%;
    width: 180px;
    height: 3px;
    background: #111;
    transform: translateX(-50%);
}

.services .serv-content .card{
    width: calc(50% - 20px);
    text-align: center;
    border-radius: 6px;
    padding: 50px 25px;
    cursor: pointer;
    transition: all 0.3s ease;
}
.services .serv-content .card:hover{
    background: crimson;
}
.services .serv-content .card .box{
    transition: all 0.3s ease;
}
.services .serv-content .card:hover .box{
    transform: scale(1.05);
    color: #fff;
}
.services .serv-content .card i{
    font-size: 50px;
    color: crimson;
    transition: color 0.3s ease;
}
.services .serv-content .card:hover i{
    color: #fff;
}
.services .serv-content .card .text{
    font-size: 25px;
    font-weight: 500;
    margin: 10px 0 7px 0;
}

@media screen and (max-width: 1050px) {
}

@media screen and (max-width: 1000px) {
}

@media screen and (max-width: 900px) {
  .bg_image {
    display: none
  }
}

@media screen and (max-width: 480px) {
  .otp-field {
    font-size: 1.5em;
  }

  .login_content_viewer {
    width: 90%;
    margin: 5%;
  }
}