@font-face {
  font-family: "Roboto Slab";
  src: local("RobotoSlab"),
  url("./Fonts/RobotoSlab-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Open Sans";
  src: local("OpenSans-Regular"),
  url("./Fonts/OpenSans-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Libre Bodoni";
  src: local("LibreBodoni-Regular"),
  url("./Fonts/LibreBodoni-Regular.ttf") format("truetype");
}

.libreBodoni {
  font-family: 'Libre Bodoni';
}

.Roboto {
  font-family: 'Roboto Slab';
}

.OpenSans {
  font-family: 'Open Sans';
}

.font12 {
  font-size: 12px !important;
}

.font14 {
  font-size: 14px !important;
}

.font36 {
  font-size: 36px;
}

.font24 {
  font-size: 24px;
}

.font22 {
  font-size: 22px;
}

.font20 {
  font-size: 20px;
}

.font18 {
  font-size: 18px !important;
}

.error_text {
  font-size: 18px;
}

.display_text {
  border: 1px solid #EB7078;
  border-radius: 5px;
  padding: 10px 15px;
  font-weight: bold;
  display: block;
}

.container-view {
  width: 100%;
 /* min-height: calc(100vh - 100px);
  margin-top: calc(110px) !important;*/
  padding: .5rem 2rem;
}

.thank_you_image {
  padding: 3%;
  width: 30%;
  margin: 0 35%;
  background-size: contain;
}

.bg-white-color {
  background-color: var(--white) !important;
}

.MuiFormControl-root {
  margin: 0 !important;
}

body.dark {
  --white: #0C0C1E;
  --grey: #060714;
  --dark: #FBFBFB;

}

.container, .container-fluid, .row{
    margin-left: 0 !important;
    margin-right: 0 !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
}

:root {
  --card-line-height: 1.2em;
  --card-padding: 1em;
  --card-radius: 0.5em;
  --color-green: #558309;
  --color-gray: #e2ebf6;
  --color-dark-gray: #c4d1e1;
  --radio-border-width: 2px;
  --radio-size: 1.5em;
  --white: #FFFFFF;
  --blue: #3C91E6;
  --light-blue: #CFE8FF;
  --grey: #eee;
  --dark-grey: #AAAAAA;
  --dark: #342E37;
  --red: #DB504A;
  --yellow: #FFCE26;
  --light-yellow: #FFF2C6;
  --orange: #FD7238;
  --light-orange: #FFE0D3;
}

.logo_container {
  position: relative;
  display: block;
  height: 100%;
  position: fixed;
  width: 34%;
}

.bg_image {
  background-repeat: no-repeat;
  background-size: contain;
  width: 97%;
  height: 92vh;
  margin: 4vh 0%;
}

.logo {
  width: 100%;
  position: absolute;
  top: 20%;
  left: 2%;
  font-size: 28px;
  text-align: center;
  color: #D38C33;
  text-transform: uppercase;
}

body {
  min-height: 100vh;
  max-height: 100vh;
  background-size: contain;
  background-repeat: repeat;
  background-image: url("./assets/Pattern.png");
  
  font-family: 'Open Sans' !important;
  font-size: 18px !important;
}

body.hide {
  background-image: url("");
}

h1.heading {
  font-size: 32px;
  font-family: "Roboto Slab";
}

h2.heading {
  font-size: 24px;
  font-family: "Roboto Slab";
}

.MuiFormControl-root .MuiInput-input, .MuiFormControl-root .MuiSelect-select  {
  font-size: 24px;
  font-family: 'Roboto Slab';
}

.MuiFormControl-root label {
  font-size: 18px;
  color: #000;
  font-family: 'Open Sans';
}

.btn-danger {
  background-color: #EB7078 !important;
  font-size: 24px !important;
  border-color: none !important;
  border-top-color : none !important;
  border-bottom-color : none !important;
  border-left-color : none !important;
  border-right-color : none !important;
}

.btn-outline-danger {
  color: #AC5056 !important;
  font-size: 24px !important;
  font-weight: 600;
}

.btn-outline-danger:hover {
  color: #fff !important;
  background-color: #EB7078 !important;
}

.addMore {
  font-size: .8em;
  font-family: "Roboto Slab";
}

.thank_you {
  font-size: 64px;
  color: #D38C33;
}

.selectView {
  width: 49%;
  display: inline-block;
  margin-right: 1%;
}

.send_link_container {
  /*background-color: #fff;
  min-height: calc(100vh - 100px);*/
}

@media screen and (max-width: 820px) {
  .login_form_container {
    width: 90%;
    margin: 5%;
  }

  .thank_you {
    font-size: 64px;
  }

  .thank_you_image {  
    height: auto;
    width: 30%;
    margin: 0 35%;
  }

  .logo_container {
    display: none;
  }

  .MuiFormControl-root .MuiSelect-select {
    padding-bottom: 3px !important;
  }

  .addMore {
    font-size: .7em;
  }

  .font12 {
    font-size: 12px !important;
  }

  .font36 {
    font-size: 24px;
  }

  .font24 {
    font-size: 18px;
  }

  .font22 {
    font-size: 14px;
  }

  .font20 {
      font-size: 18px;
  }

  .font18 {
    font-size: 12px !important;
  }

  .error_text {
    font-size: 12px;
  }

  h1.heading {
    font-size: 18px;
    font-family: "Roboto Slab";
  }

  h2.heading {
    font-size: 14px;
    font-family: "Roboto Slab";
  }

  .btn-outline-danger {
    font-size: 14px !important;
  }

  .MuiFormControl-root .MuiInput-input, .MuiFormControl-root .MuiSelect-select  {
    font-size: 14px;
  }

  .MuiFormControl-root label {
    font-size: 14px;
  }

  .btn-danger {
    font-size: 16px !important;
  }

  .container-view {
    padding: 2.5rem 2rem;
  }

  body {
    background-image: url("./assets/mobile-bg.png");
  }

  footer {
    /*padding: 5px 0;*/
    color: #000;
    font-size: .6em;
    opacity: .8;
  }

  .container-view {
    min-height: auto;
  }

  .lang_btns .active + label {
    color: #FFFFFF !important;
    font-weight: bold;
    border-bottom: 3px solid #fff !important;
    border-radius: 2px !important;
  }

  .container-view > div {
    min-height: auto;
  }

  footer {
    text-align: center;
    width: 100%;
  }

  footer div {
    position: static !important;
    justify-content: center;
  }

  .mobile-login {
    position: absolute !important;
    bottom: 5px;
  }

  footer.login_form {
    width: 90%;
  }

  form.rating {
    font-size: calc(.6rem + 0.46vw);
  }


  h4 {
    font-size: .9rem !important;
  }

  .send_link_container {
  }

  .DatePicker input {
    padding: 6.5px 0px !important;
  }

  .DatePicker .MuiTextField-root {
    border-bottom-color: none !important;
    border-radius: 0 !important
  }


  .DatePicker fieldset {
    border-bottom-color: none !important;
    border-bottom-width: 0 !important;
  }
}

@media screen and (max-width: 668px) {
  .send_link_container {
  }

  .selectView {
    width: 100%;
    margin-right: 0;
  }

  .container-view {
    padding: 1rem 1rem !important;
    /*margin-top: calc(70px + 70px + 80px) !important;*/
  }

  .thank_you_image {
    width: 50%;
    margin: 0 25%;
  }

  .thank_you {
    font-size: 24px;
  }

  .font36 {
    font-size: 18px;
  }

  .font24 {
    font-size: 14px;
  }

  .font20 {
      font-size: 16px;
  }
}
