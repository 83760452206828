.card-radio {
	font-size: 20px;
	font-weight: bold;
	border-color: #ccc;
	padding: .7em 1em;
	position: relative;
}

.tick_icon {
	position: absolute;
	right: 1px;
	top: 3px;
	font-size: .8em;
}

.checkbox {
	position: relative;
}

.checkbox_tick_icon {
	position: absolute;
	right: -7px;
	top: 2px;
	font-size: .8em;
	color: #fff;
}