input[type="range"]{
  -webkit-appearance:none;
  width:100%;
  height:12px;
  background: linear-gradient(270deg, rgba(131,58,180,1) 0%, rgba(249,83,83,0.9948354341736695) 0%, rgba(232,58,58,1) 50%, rgba(252,176,69,1) 100%);
  background-position:center;
  background-repeat:no-repeat;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  outline: none;
  border-radius: 20px;
}

input[type="range"]::-webkit-slider-thumb{
  -webkit-appearance:none;
  width:30px;
  height:30px;
  border-radius: 100%;
  background: rgba(232,58,58,1);
  position:relative;
  border: 3px solid rgba(232,58,58,1);
  z-index:3;
  cursor: pointer;
}

.fa-angry {
  color: #f12711;
}

.smiley span {
  font-size: 30px;
}

input[type="range"]::-moz-range-progress {
  background-color: #43e5f7; 
}
input[type="range"]::-moz-range-track {  
  background-color: #9a905d;
}
/* IE*/
input[type="range"]::-ms-fill-lower {
  background-color: #43e5f7; 
}
input[type="range"]::-ms-fill-upper {  
  background-color: #9a905d;
}

