.Mui-active {
	color: #EB7078 !important;
}

.Mui-completed, .Mui-checked {
	color: #D38C33 !important;
}

.Mui-completed + .MuiStep-root .css-z7uhs0-MuiStepConnector-line {
	border-color : #1D7353 !important;
}

.css-z7uhs0-MuiStepConnector-line {
	border-top-width: 1px !important;
}

.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root {
	width: 1em;
	height: 1em;
}

.slider svg {
	width: 100%;
	margin: 0 0%;
}

.MuiStepConnector-root {
	left: calc(-50% + 14px);
    right: calc(50% + 14px);
}

.MuiMobileStepper-root {
  padding: 8px 0px !important;
}

.MuiMobileStepper-root span.MuiLinearProgress-root {
  width: 80% !important;
  background-color: #f5c2c7 !important;
}

.MuiMobileStepper-root span.MuiLinearProgress-root .MuiLinearProgress-bar {
  background-color: #dc3545 !important;
}